import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createRelease } from '../../../services/Release';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../constants/AppConstant';
import React from 'react';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';

export default function CreateRelease() {
  const [priority, setPriority] = React.useState('');
  const { projectId = '', productId = '' } = useParams();

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const validationSchema = yup.object({
    name: yup.string().required('Please enter name'),
    // type: yup.string().required('Please enter type'),
    description: yup.string().required('Please enter description'),
    plannedReleaseDate: yup.string().required('Please enter planned release date'),
    version: yup.string().required('Please enter version'),
  });

  /**
   * @description Create or edit release submission
   */
  const onReleaseFormSubmit = async () => {
    console.log('onReleaseFormSubmit Started');
    try {
      setOpen(true);
      // Create new release
      const result = await createRelease(projectId, productId, formik.values);
      // check release has been created or not
      if (result.data) {
        enqueueSnackbar('Release Created Successfully', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
        setOpen(false);
      } else {
        enqueueSnackbar('Error in creating release', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
        setOpen(false);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error occured while creating a release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.Error,
      });
      setOpen(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      // type: '',
      description: '',
      plannedReleaseDate: '',
      // linkedGroupId: '',
      version: '',
      reference: '',
      status: '',
      tagUrl: ''
    },
    onSubmit: onReleaseFormSubmit,
  });

  const handleChange = (event: SelectChangeEvent) => {
    setPriority(event.target.value as string);
  };
  const handleStatusChange = (event: SelectChangeEvent) => {
    formik.setFieldValue('status', event.target.value as string);
  };
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <MainContentHeader label="Release Commitment" />
        <Grid item xs={8} md={8} lg={8}>
          {/* 
              List of attributes
              1. Release Topic name
              2. Description
              3. Planned Release date
              4. Priority (high,medium,low)
              5. Reference ()
              6. Release Demo (demo1)
              7. Source (person / document)
              8. Topic Owners (person-1,person-2,person-3)
            */}
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  name="name"
                  margin="normal"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <br></br>
              <Grid item xs={12} sx={{}}>
                <FormControl fullWidth>
                  <InputLabel id="priority-select-label">Status</InputLabel>
                  <Select
                    labelId="priority-select-label"
                    id="status"
                    label="Status"
                    sx={{ textAlign: 'left' }}
                    onChange={handleStatusChange}
                  >
                    <MenuItem value={'created'}>Created</MenuItem>
                    <MenuItem value={'planned'}>Planned</MenuItem>
                    <MenuItem value={'revoked'}>Revoked</MenuItem>
                    <MenuItem value={'committed'}>committed</MenuItem>
                    <MenuItem value={'breached'}>Breached</MenuItem>
                    <MenuItem value={'recalled'}>Recalled</MenuItem>
                    <MenuItem value={'released'}>Released</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <br></br>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="description"
                  name="description"
                  margin="normal"
                  label="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>

              <br></br>
              <Grid item xs={12} sx={{}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Planned Release Date"
                    name="plannedReleaseDate"
                    value={formik.values.plannedReleaseDate}
                    onChange={(value: any) => {
                      formik.setFieldValue('plannedReleaseDate', Date.parse(value));
                    }}
                    slotProps={{
                      textField: {
                        id: 'plannedReleaseDate',
                        variant: 'outlined',
                        fullWidth: true,
                        sx: { marginTop: 2 },
                        error: formik.touched.plannedReleaseDate && Boolean(formik.errors.plannedReleaseDate),
                        helperText: formik.touched.plannedReleaseDate && formik.errors.plannedReleaseDate,
                      },
                    }}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </Grid>
              <br></br>
              <Grid item xs={12} sx={{}}>
                <FormControl fullWidth>
                  <InputLabel id="priority-select-label">Priority</InputLabel>
                  <Select
                    labelId="priority-select-label"
                    id="priority"
                    value={priority}
                    label="Priority"
                    sx={{ textAlign: 'left' }}
                    onChange={handleChange}
                  >
                    <MenuItem value={'High'}>High</MenuItem>
                    <MenuItem value={'Medium'}>Medium</MenuItem>
                    <MenuItem value={'Low'}>Low</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <br></br>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="reference"
                  name="reference"
                  margin="normal"
                  label="Reference"
                  value={formik.values.reference}
                  onChange={formik.handleChange}
                  error={formik.touched.reference && Boolean(formik.errors.reference)}
                  helperText={formik.touched.reference && formik.errors.reference}
                />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="tagUrl"
                  name="tagUrl"
                  margin="normal"
                  label="Release Tag URL"
                  value={formik.values.tagUrl}
                  onChange={formik.handleChange}
                  error={formik.touched.tagUrl && Boolean(formik.errors.tagUrl)}
                  helperText={formik.touched.tagUrl && formik.errors.tagUrl}
                />
              </Grid>
              <br></br>
              {/* <Grid item xs={12} sx={{}}>
                  <TextField
                    required
                    fullWidth
                    id="linkedGroupId"
                    name="linkedGroupId"
                    margin="normal"
                    label="User Group"
                    value={formik.values.linkedGroupId}
                    onChange={formik.handleChange}
                    error={formik.touched.linkedGroupId && Boolean(formik.errors.linkedGroupId)}
                    helperText={formik.touched.linkedGroupId && formik.errors.linkedGroupId}
                  />
                </Grid> */}
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="version"
                  name="version"
                  margin="normal"
                  label="Release Version"
                  value={formik.values.version}
                  onChange={formik.handleChange}
                  error={formik.touched.version && Boolean(formik.errors.version)}
                  helperText={formik.touched.version && formik.errors.version}
                />
              </Grid>
              <br />
              <Grid container xs={12} sx={{ marginLeft: 6, minWidth: '100%', textAlign: 'center' }}>
                <Grid item>
                  <center>
                    <Button sx={{ marginX: 2 }} color="primary" variant="contained" type="submit">
                      Create
                    </Button>

                    <Button sx={{ marginX: 2 }} color="inherit" variant="contained" type="reset">
                      Clear
                    </Button>
                  </center>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}
