import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { APP_AUTH } from '../constants/AppConstant';
/**
 * @description Authentication Interceptor. Attaches the token to the requests.
 */
axios.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  // const [cookies, setCookie] = useCookies(['auth_token'])
  // // attach token only if present
  const cookies = document.cookie;
  request.headers[APP_AUTH.COOKIE_TOKEN_KEY] = document.cookie;
  request.headers[APP_AUTH.AUTH_HEADER] = parseCookieExtraction(cookies);
  request.headers[APP_AUTH.AUTH_TOKEN_HEADER_KEY] = parseCookieExtraction(cookies);
  request.headers[APP_AUTH.SCHEME_KEY] = APP_AUTH.SCHEME_VALUE;
  return request;
});

const parseCookieExtraction = (cookie:string):string | undefined => {
  let items:string[]= cookie.split(';');
  let result ;
  for(let content in items){
    let temp = items[content].trim();
    if(temp.startsWith(APP_AUTH.AUTH_HEADER)){
      result = items[content];
      break;
    }
  }
  /**
   * Break the Token key pair
   */
  //console.log(result)
  return result?.split('=')[1];

}
/**
 * @description Response Interceptor. Intercept all requet's response and react/raise notification accordingly
 */
axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error) => {
    if (error?.response?.status === 401) {
      alert('User Unauthorized');
    }
  }
);

/**
 * @description Axios get request
 */
function getRequest({
  BASE_URL = process.env.REACT_APP_AUTH_SERVICE_ENDPOINT,
  END_POINT = '',
  params = {},
  headers = {},
}): Promise<any> {
  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: END_POINT,
    params,
    headers,
  });
}

/**
 * @description Axios post request
 */
function postRequest({
  BASE_URL = process.env.REACT_APP_AUTH_SERVICE_ENDPOINT,
  END_POINT = '',
  params = {},
  data = {},
  headers = {},
}): Promise<any> {
  return axios({
    method: 'POST',
    baseURL: BASE_URL,
    url: END_POINT,
    params,
    data,
    headers,
  });
}

/**
 * @description Axios delete request
 */
function deleteRequest({
  BASE_URL = process.env.REACT_APP_AUTH_SERVICE_ENDPOINT,
  END_POINT = '',
  params = {},
}): Promise<any> {
  return axios({
    method: 'DELETE',
    baseURL: BASE_URL,
    url: END_POINT,
    params,
  });
}

/**
 * @description Axios put request
 */
function putRequest({
  BASE_URL = process.env.REACT_APP_AUTH_SERVICE_ENDPOINT,
  END_POINT = '',
  data = {},
  params = {},
}): Promise<any> {
  return axios({
    method: 'PUT',
    baseURL: BASE_URL,
    url: END_POINT,
    data,
    params,
  });
}

/**
 * @description Axios put request
 */
function patchRequest({
  BASE_URL = process.env.REACT_APP_AUTH_SERVICE_ENDPOINT,
  END_POINT = '',
  data = {},
  params = {},
}): Promise<any> {
  return axios({
    method: 'PATCH',
    baseURL: BASE_URL,
    url: END_POINT,
    data,
    params,
  });
}

const HttpClient = {
  GET: getRequest,
  POST: postRequest,
  PUT: putRequest,
  PATCH: patchRequest,
  DELETE: deleteRequest,
};

export default HttpClient;
