import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Container,Grid, IconButton } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { enqueueSnackbar } from 'notistack';
import { createUserGroup } from '../../../services/Auth';
import { CREATED_SUCCESS } from '../../../translations/properties_dev';
import { NOTIFICATION_VARIANTS } from '../../../constants/AppConstant';
export default function CreateUserGroup() {
  const [open, setOpen] = React.useState(false);
  const validationSchema = yup.object({});
  const submit = async () => {
    console.log(`Rest API Call Save Release`);
    setOpen(true);
    try {
      const result = await createUserGroup(formik.values);
      if (result.data) {
        enqueueSnackbar(CREATED_SUCCESS, {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
        setOpen(false);
      } else {
        enqueueSnackbar('Error in creating release', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
        setOpen(false);
      }
    } catch (err) {
      enqueueSnackbar('Error in creating release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.WARN,
      });
      console.log(err);
      setOpen(false);
    }
  };
  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      description: '',
    },
    onSubmit: submit,
  });

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={2}>
        <MainContentHeader label="Create Usergroup" />

        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
            <TextField
              required
              fullWidth
              id="name"
              name="name"
              margin="normal"
              label="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              required
              fullWidth
              id="description"
              name="description"
              margin="normal"
              label="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
            <center>
              <Button variant="contained" size="large" type="submit">
                Submit
              </Button>
            </center>
          </Box>
        </form>
      </Grid>
    </Container>
  );
}
