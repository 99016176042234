import { Button, Dialog, DialogTitle, Divider, Grid, MenuItem, Paper, Select, TextField, Toolbar, Typography } from '@mui/material';
import { IHeaderLabel } from '../../../styles/components';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { SideBarIconColor } from '../../../styles/components/Layout';
import React, { ReactElement } from 'react';
import { FilterInnerGrid, FilterPane } from '../filter/layout/FilterPane';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useNavigate } from 'react-router-dom';
import { FilterParams, TYPE_TEXT_FIELD } from '../filter/config/FilterParams';
type Props = {
  buttonLabel?: string;
  buttonHref?: string;
  button1Label?: string;
  button1Href?: string;
  label: string;
  filter?: ReactElement;
  params?: Array<FilterParams>;
  callback?: any;
  clearFilter?: any;
};
export default function MainContentHeader(props: Props) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <IHeaderLabel>
            <AccountTreeIcon sx={SideBarIconColor} /> {props.label}
            {props.buttonHref ? (
              <Button variant="outlined" sx={{ marginLeft: 2 }} href={props.buttonHref}>
                {props.buttonLabel}
              </Button>
            ) : (
              <></>
            )}
            {props.button1Href ? (
              <Button variant="outlined" sx={{ marginLeft: 2 }} href={props.button1Href}>
                {props.button1Label}
              </Button>
            ) : (
              <></>
            )}
            {props.filter}
          </IHeaderLabel>
        </Grid>
        {/* <Grid item>
          <Box textAlign={'right'} mr={4} mb={2}>
            {props.child}
          </Box>
        </Grid> */}
      </Grid>
    </>
  );
}
type FilterHolder = {
  [key: string]: string | undefined;
};
export function MainContentHeaderFilter(props: Props) {
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState<FilterHolder>({});
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  const handleChange = (field: string, event: any) => {
    const filter: FilterHolder = query;
    filter[field] = event.target.value;
    setQuery(filter);
    console.log('Event ', query);
  };
  const TextFields = (field: FilterParams) => {
    return (
      <>
        <Typography sx={{ marginLeft: 2 }}>{field.label}</Typography>
        <TextField
          onChange={(evt) => {
            handleChange(field.key, evt);
          }}
          size="small"
          sx={{ margin: 2 }}
          id={field.key}
          label={field.label}
          variant="outlined"
        />
      </>
    );
  };

  const DropDown = (field: FilterParams): any => {
    if (field.enums) {
      return (
        <>
          <Typography sx={{ marginLeft: 2 }}>{field.label}</Typography>
          <Select
            labelId="demo-simple-select-label"
            id={field.key}
            // value={age}
            label={field.label}
            size="small"
            sx={{ m: 2 }}
            onChange={(evt) => {
              handleChange(field.key, evt);
            }}
          >
            {field.enums.map((item) => {
              return <MenuItem value={item}>{item.toUpperCase()}</MenuItem>;
            })}
          </Select>
        </>
      );
    } else {
      return <></>;
    }
  };

  const populateField = (field: FilterParams) => {
    return field.type === TYPE_TEXT_FIELD ? TextFields(field) : DropDown(field);
  };

  const buildParams = (query: FilterHolder): string[] => {
    console.log(Object.keys(query));
    // for(){

    // }
    return Object.keys(query);
  };

  const parseQueryPushBack = () => {
    setOpen(false);
    const keys: string[] = buildParams(query);
    let queryString = '';
    for (let item in keys) {
      console.log(`${keys[item]} - ${query[keys[item]]}`);
      queryString += `${keys[item]}=${query[keys[item]]}&`;
    }
    props.callback(`?${queryString}`);
    //navigate(`?asc=false&status=grooming`, { replace: true });
  };
  const clearFilter = () => {
    props.callback('?');
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <IHeaderLabel >
            <AccountTreeIcon sx={SideBarIconColor} /> {props.label}
            {props.buttonHref ? (
              <Button variant="outlined" sx={{ marginLeft: 2 }} href={props.buttonHref}>
                {props.buttonLabel}
              </Button>
            ) : (
              <></>
            )}
            {props.button1Href ? (
              <Button variant="outlined" sx={{ marginLeft: 2 }} href={props.button1Href}>
                {props.button1Label}
              </Button>
            ) : (
              <></>
            )}
            {props.filter}
            <Paper elevation={3}>
              <Button sx={{ float: 'right', marginTop: 2 }} variant="contained" onClick={handleClickOpen}>
                <FilterAltIcon fontSize="medium" /> Filter
              </Button>
            </Paper>
          </IHeaderLabel>
        </Grid>
        <Dialog onClose={handleClose} open={open} sx={{padding:2}}>
          <DialogTitle>Filter</DialogTitle>
          <Divider />
          {props && props.params &&  props.params.map((fieldElement) => {
            return populateField(fieldElement);
          })}
          <Button sx={{ m: 2 }} variant="contained" size='small' onClick={parseQueryPushBack}>
            Apply
          </Button>
          <Button sx={{ m: 2 }} variant="contained" size='small' onClick={clearFilter}>
            Clear
          </Button>
        </Dialog>
        {/* <Grid item>
          <Box textAlign={'right'} mr={4} mb={2}>
            {props.child}
          </Box>
        </Grid> */}
      </Grid>
    </>
  );
}
