import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import { Link as RouteLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ILogo } from '../../styles/components/ILogo';

import * as LoginService from '../../services/Auth';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../constants/AppConstant';
export default function ChangePasswordNoAuth() {
  const [open, setOpen] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const validationSchema = yup.object({
    newPassword: yup.string().required('Please enter email'),
    confirmPassword: yup.string().required('Please enter email')
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      newPassword: '',
      confirmPassword: '',
      oneTimePassword:''
    },
    onSubmit: async () => {
      console.log('Form Submitted',searchParams);
      const body = {
        newPassword: formik.values.newPassword,
        confirmPassword: formik.values.confirmPassword,
        oneTimePassword: searchParams.get("resetKey"),
      };
      setOpen(true);
      try {
        const { code, message }: any = await LoginService.resetPassword(body);
        if (code === 200 && message !== undefined) {
          enqueueSnackbar(message, {
            autoHideDuration: 3000,
            variant: NOTIFICATION_VARIANTS.SUCCESS,
          });
          setOpen(false);
        } else {
          enqueueSnackbar('Error while login', {
            autoHideDuration: 3000,
            variant: NOTIFICATION_VARIANTS.ERROR,
          });

          setOpen(false);
        }
      } catch (err) {
        enqueueSnackbar('Error while login', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.ERROR,
        });
        setOpen(false);
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid sx={{ paddingTop: 2 }}>
          <ILogo src="/assets/Single_mainlogo_tm.png" />
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="newPassword"
              label="New Password"
              type="password"
              name="newPassword"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              helperText={formik.touched.newPassword && formik.errors.newPassword}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              type="password"
              name='confirmPassword'
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              autoFocus
            />

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Get Password Reset Link
            </Button>
            <Grid container>
              <Grid item xs>
                <Link variant="body2">
                  <RouteLink to="/auth/login">Go Back</RouteLink>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </Container>
  );
}
