import React, { useEffect, useState } from 'react';
import { Avatar, Box, Container, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ReleaseInsights, Releases } from '../../../utils/interface/ReleaseInterface';
import HeaderCard from '../../shared-components/header-card/HeaderCard';
import { PieChart } from '@mui/x-charts/PieChart';
import { getReleaseById } from '../../../services/Release';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { getReleaseActionByReleaseId, getReleaseActionInsights } from '../../../services/Auth';
import PersonIcon from '@mui/icons-material/Person';
import { ReleaseActionsListInterface } from '../../../utils/interface/ReleaseActionsInterface';
import { EmptyResult, extractResult, isResultNotEmpty } from '../../shared-components/table/TableComponent';
export function ReleaseManifest(props: any): React.ReactElement {
  const { projectId = '', productId = '', releaseId = '' } = useParams();
  const [open, setOpen] = React.useState(false);
  const [release, setReleases] = useState<Releases>();
  const [releaseActions, setReleaseActions] = useState<ReleaseActionsListInterface>();
  const [releaseInsight, setReleaseInsight] = useState<ReleaseInsights>({});
  const releaseInsights = async () => {
    if (releaseId) {
      setOpen(true);
      const releaseInsights = await getReleaseActionInsights(projectId, releaseId);
      setReleaseInsight(releaseInsights.data);
      setOpen(false);
    }
  };
  const releaseById = async () => {
    if (releaseId && productId) {
      setOpen(true);
      const release = await getReleaseById(projectId, productId, releaseId);
      setReleases(release.data);
      setOpen(false);
    }
  };
  const getReleaseActionByReleaseIdAPI = async () => {
    if (releaseId && projectId) {
      setOpen(true);
      const release = await getReleaseActionByReleaseId(projectId, releaseId);
      setReleaseActions(release.data);
      setOpen(false);
    }
  };
  useEffect(() => {
    releaseById();
    releaseInsights();
    getReleaseActionByReleaseIdAPI();
  }, []);

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <HeaderCard title={release?.name} description={release?.description} />
          </Grid>
          {/* {release?.status !== 'freezed' ? publishComponent() : releaseCallBack()} */}
        </Grid>
      </Box>
      <Paper elevation={3}>
        <PieChart
          sx={{ marginTop: 4, marginBottom: 4 }}
          series={[
            {
              data: [
                { id: 0, value: releaseInsight?.approvedCount ? releaseInsight?.approvedCount : 0, label: 'Accepted' },
                { id: 1, value: releaseInsight?.deniedCount ? releaseInsight?.deniedCount : 0, label: 'Denied' },
                {
                  id: 2,
                  value: releaseInsight?.noActionCount ? releaseInsight?.noActionCount : 0,
                  label: 'Not Acknowledged',
                },
                // releaseInsight?.approvedCount ? releaseInsight?.approvedCount : 0,
                // releaseInsight?.deniedCount ? releaseInsight?.deniedCount : 0,
                // 0,
              ],
            },
          ]}
          width={600}
          height={300}
        />
      </Paper>

      <Paper elevation={3} sx={{ mt: 2 }}>
        <Typography variant="h6" component="div" sx={{ p: 2 }}> Release Actions</Typography>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {isResultNotEmpty(releaseActions) ? (
            extractResult(releaseActions).map((row: any, index: number) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={(row.users && row.users.length>0)?row.users[0].firstName:''} secondary={row.message} />
              </ListItem>
            ))
          ) : (
            <EmptyResult />
          )}
         
        </List>
      </Paper>

      {/* <BarChart
        xAxis={[
          {
            id: 'barCategories',
            data: ['Accepted', 'Denied', 'Rejected'],
            scaleType: 'band',
          },
        ]}
        series={[
          {
            data: [
              releaseInsight?.approvedCount ? releaseInsight?.approvedCount : 0,
              releaseInsight?.deniedCount ? releaseInsight?.deniedCount : 0,
              0,
            ],
          },
        ]}
        width={500}
        height={300}
      /> */}
    </Container>
  );
}
