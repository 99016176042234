import { PaginationConst } from '../constants/AppConstant';
import { PageItemCount } from '../utils/lib/PaginationUtil';
import HttpClient from './HttpClient';
import { RELEASE_SERVICE_HOST, RELEASES_API_PREFIX } from './RestConstants';

/**
 * @description Get Projects
 * @param {Object} filterObj
 * @returns {Promise}
 */
export async function getProjects(pageNumber:number=0) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects?skip=${PageItemCount(pageNumber)}&size=${PaginationConst.PAGE_SIZE}`,
  });

  return response || {};
}


/**
 * @description Get Projects
 * @param {Object} filterObj
 * @returns {Promise}
 */
export async function getProjectsById(projectId:string) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}`,
  });

  return response || {};
}
/**
 * @description Create new release
 * @param {Object} releaseObj
 * @returns {Promise}
 */
export async function createProject(releaseObj: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects`,
    data: releaseObj,
  });

  return response;
}
