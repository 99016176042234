import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form, useParams } from 'react-router-dom';
import { ReleaseNotification, Releases } from '../../../utils/interface/ReleaseInterface';
import { getReleaseActionByActionId, getReleaseById, voteToRelease } from '../../../services/Release';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CloseIcon from '@mui/icons-material/Close';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { enqueueSnackbar } from 'notistack';
import { CREATED_SUCCESS } from '../../../translations/properties_dev';
import { NOTIFICATION_VARIANTS } from '../../../constants/AppConstant';
import { getReleaseActionInsights } from '../../../services/Auth';

export function ReleaseVoting(): React.ReactElement {
  const { projectId = '', productId = '', releaseId = '', actionId = '' } = useParams();
  const [release, setRelease] = useState<Releases>();
  const [releaseNotification, setReleaseNotification] = useState<ReleaseNotification>();
  const [inputValue, setInputValue] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [alignment, setAlignment] = React.useState('not_related');
  const [comment, setComment] = React.useState('None');

  const [open, setOpen] = React.useState(false);
  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
  };

  const getReleaseDetails = async () => {
    if (productId && releaseId) {
      const releaseDetails = await getReleaseById(projectId, productId, releaseId);
      setRelease(releaseDetails.data);
    }
  };
  const fetchReleaseVotingAPI = async () => {
    const actionResult = await getReleaseActionInsights(projectId, releaseId);
    setReleaseNotification(actionResult.data);
  };
  useEffect(() => {
    fetchReleaseVotingAPI();
    getReleaseDetails();
  }, []);
  const commitRelease = (releaseTopicId: string) => {
    voteToRelease(projectId, actionId, {
      action: alignment,
      comment: comment,
    })
      .then((data) => {
        setOpen(false);
        enqueueSnackbar(CREATED_SUCCESS, {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
      })
      .catch((err) => {
        enqueueSnackbar('Error in creating release', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
        console.error(err.message);
        setOpen(false);
      });
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const card = (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`Version ${release?.version}`}
        </Typography>
        <Typography variant="h5" component="div">
          {release?.name}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {/* {//release?.plannedReleaseDate} */}
        </Typography>
        <Typography variant="body2">{release?.description}</Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </React.Fragment>
  );

  const defaultCard = (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          You successfully responded to this release already.
        </Typography>
      </CardContent>
    </Card>
  );
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const voteConsole = (
    <React.Fragment>
      <Box>
        <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleChange} aria-label="Platform">
          <ToggleButton value="APPROVED">Approve</ToggleButton>
          <ToggleButton value="NOT_RELEVANT">Not Releated</ToggleButton>
          <ToggleButton value="DENIED">Deny</ToggleButton>
        </ToggleButtonGroup>
        <br />
        <br />
        <TextField
          hiddenLabel
          id="filled-hidden-label-normal"
          defaultValue="Comment"
          variant="filled"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setComment(event.target.value);
          }}
        />
        <br />
        <br />
        <center>
          <Button
            variant="contained"
            onClick={(e) => {
              commitRelease(inputValue);
            }}
          >
            Confirm
          </Button>
        </center>
      </Box>
    </React.Fragment>
  );

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message="Note archived" action={action} />
      <Card variant="outlined">{card}</Card>

      <br />
      {releaseNotification?.action !== 'APPROVED' && releaseNotification?.action !== 'DENIED' ? voteConsole : defaultCard}
    </Container>
  );
}
