import {  Button, Tooltip } from '@mui/material';

type Props = {
  link: string;
  title:string;
  children?: JSX.Element | undefined;
};
export function ToolTipButton(props: Props): React.ReactElement {
  return (
    <>
      <Tooltip title={props.title}>
        <Button
          sx={{ marginLeft: 2 }}
          target="_blank"
          rel="noopener noreferrer"
          variant="outlined"
          href={props.link}
        >
          {props.children}
        </Button>
      </Tooltip>
    </>
  );
}
