import { BaseVariant } from 'notistack';
export const APP_NAME = 'Release Pilot';
export const APP_AUTH = {
  SECRECT_TOKEN_KEY: 'authToken',
  COOKIE_TOKEN_KEY: 'auth_token',
  AUTH_HEADER: 'auth_token',
  SCHEME_KEY: 'scheme',
  SCHEME_VALUE: 'http',
  AUTH_TOKEN_HEADER_KEY: 'Authorization',
};

export const PaginationConst = {
  PAGE_SIZE: 20,
};

export const APP_REGEX = {
  PASSWORD_VALIDATOR_REGEX: /^[a-zA-Z\d!@.]{8,}$/,
};

export const NOTIFICATION_VARIANTS: { [key: string]: BaseVariant } = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARN: 'warning',
  INFO: 'info',
};
