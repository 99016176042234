import { PaginationConst } from "../../constants/AppConstant";

export const  PageCount= (totalCount:number | undefined):number => {      
      return Math.ceil((totalCount)?(totalCount/PaginationConst.PAGE_SIZE):0);
}
/**
 * 
 * @param page Requested Pagenumber will be attached along with the API
 * @returns 
 */
export const PageItemCount =  (page:number | undefined):number => {   
    console.log(page);
    if(page){
        if(page<=1){
            return 0;
        }else{
            return ((page-1)*PaginationConst.PAGE_SIZE);
        }
    }  else{
        return 0;
    }
}