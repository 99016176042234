import { Autocomplete, Avatar, Chip, Grid, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../constants/AppConstant';
import { User, UsersListInterface } from '../../../utils/interface/UsersListInterface';
import { findUserById, searchuser } from '../../../services/Auth';

import FaceIcon from '@mui/icons-material/Face';
interface Props {
  open?: boolean;
  callback: any;
  ownerUser?: User;
}
export function UserSelectorDropdown(props: Props): React.ReactElement {
  const [open, setOpen] = React.useState(false);
  const [userList, setUserList] = React.useState<Array<User>>();
  const [userId, setUserId] = React.useState<string>();
  const searchUserAPI = async (pageNumber: number = 1) => {
    setOpen(true);
    try {
      //const releaseTopics: any = await getReleaseTopicsPagination(projectId, pageNumber, {});
      searchuser()
        .then((userlist) => {
          console.log('Result ', userlist);
          setUserList(userlist.data.result);
          setOpen(false);
        })
        .catch((err) => {
          console.log('Result ', err);
          setOpen(false);
        });
    } catch (err) {
      console.log('Failure Catched', err);
      enqueueSnackbar('Error in creating release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.WARN,
      });
      setOpen(false);
    }
  };

  const fetchUserDetailsAPI = async (userId: string) => {
    setOpen(true);
    try {
      //const releaseTopics: any = await getReleaseTopicsPagination(projectId, pageNumber, {});
      findUserById(userId)
        .then((userlist) => {
          console.log('Result ', userlist);
          setUserList(userlist.data);
          setOpen(false);
        })
        .catch((err) => {
          console.log('Result ', err);
          setOpen(false);
        });
    } catch (err) {
      console.log('Failure Catched', err);
      enqueueSnackbar('Error in creating release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.WARN,
      });
      setOpen(false);
    }
  };
  useEffect(() => {
    searchUserAPI();
  }, []);
  // const findPresentUser = (userList:Array<User> | undefined):User => {
  //   if(userList && userList.length>0){
  //     return userList;
  //   }else{
  //     return {}};
  //   }
  // }

  const userAutoComplete = () => {
    return (
      <Autocomplete
        disablePortal
        // value={findPresentUser(userList)}
        options={userList && userList ? userList : []}
        getOptionLabel={(option) => option.userName}
        fullWidth
        onChange={(event: any, newValue: User | null) => {
          if (newValue && newValue._id) {
            setUserId(newValue.userName);
            props.callback(newValue.userName);
          }
        }}
        renderInput={(params) => <TextField {...params} label="Topic Owners" />}
      />
    );
  };

  const showOwnerUser = () => {
    return <Chip avatar={<Avatar>M</Avatar>} label="Avatar" />;
  };
  return <>{props.ownerUser && props.ownerUser && props.ownerUser._id ? showOwnerUser() : userAutoComplete()}</>;
}
