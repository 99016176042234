import {
  Box,
  TextField,
  Card,
  Typography,
  CssBaseline,
  List,
  Stack,
  Chip,
  Divider,
  Modal,
  Button,
  Paper,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { GridSaveAltIcon } from '@mui/x-data-grid';
import { ConversationsInterface } from '../../../utils/interface/Conversations';
import { createConversations, getConversations } from '../../../services/Conversations';
import { EmptyResultPaper } from '../../shared-components/table/TableComponent';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { NOTIFICATION_VARIANTS } from '../../../constants/AppConstant';
import dayjs from 'dayjs';
import { ParseToDate } from '../../../utils/parser/DateParser';
type Props = {};
const style = {
  position: 'absolute' as 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
export function Conversations(props: Props): React.ReactElement {
  const [conversations, setConversations] = useState<Array<ConversationsInterface>>([]);
  const { projectId = '', topicId = '' } = useParams();
  const [modelOpen, setModelOpen] = React.useState(false);
  const handleOpen = () => setModelOpen(true);
  const handleClose = () => setModelOpen(false);
  useEffect(() => {}, []);

  const [open, setOpen] = React.useState(false);

  const validationSchema = yup.object({
    messageTitle: yup.string().required('Please enter MessageTitle'),
    description: yup.string().required('Please enter description'),
  });
  const onsubmit = async () => {
    console.log(`Rest API Call Save Release`);
    setOpen(true);
    try {
      const result = await createConversations(projectId, topicId, formik.values);
      if (result) {
        enqueueSnackbar('Created Successfully', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
        getConversationsAPI();

        setOpen(false);
        setModelOpen(false);
        formik.setValues({
          messageTitle: '',
          description: '',
        });
        //navigate(`/products/${productId}/release`);
      } else {
        enqueueSnackbar('Error in creating', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
        setOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    validationSchema,
    initialValues: {
      messageTitle: '',
      description: '',
    },
    onSubmit: onsubmit,
  });

  const getConversationsAPI = async () => {
    const conversations = await getConversations(projectId, topicId);
    setConversations(conversations.result);
  };
  useEffect(() => {
    setOpen(true);
    getConversationsAPI();
    setOpen(false);
  }, []);

  const statusChip = (status: string | undefined) => {
    return (
      <>
        <Chip label={status} color={status === 'waiting' ? 'warning' : 'success'} />
      </>
    );
  };
  const loadConversations = () => {
    return (
      <List sx={{ width: '60%', minHeight: 600, bgcolor: 'background.paper' }}>
        {conversations ? (
          conversations.map((value) => (
            <Paper elevation={3} sx={{marginTop:1,borderRadius:2}}>
              <Box sx={{ p: 2 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography gutterBottom variant="h6" component="div">
                    {value.messageTitle} {/*statusChip(value.status) */}
                  </Typography>
                  <Divider/>
                  <Typography gutterBottom variant="caption" component="div">
                    {ParseToDate(value.createdDate)}
                  </Typography>
                </Stack>
                <Typography color="text.secondary" variant="body2">
                  {value.description}
                </Typography>
              </Box>
            </Paper>
          ))
        ) : (
          <EmptyResultPaper />
        )}
      </List>
    );
  };

  return (
    <Box sx={{ pb: 7 }}>
      <CssBaseline />
      <Button variant="outlined" onClick={handleOpen}>
        NEW
      </Button>
      {loadConversations()}

      <Modal
        open={modelOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: 2 }}
      >
        <Box sx={style}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Message Title"
              variant="outlined"
              sx={{ width: '95%', margin: 1 }}
              id="messageTitle"
              name="messageTitle"
              value={formik.values.messageTitle}
              onChange={formik.handleChange}
              error={formik.touched.messageTitle && Boolean(formik.errors.messageTitle)}
              helperText={formik.touched.messageTitle && formik.errors.messageTitle}
            />

            <TextField
              id="description"
              name="description"
              label="Description"
              variant="outlined"
              multiline
              rows={4}
              sx={{ width: '95%', margin: 1 }}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />

            <LoadingButton
              sx={{ padding: 1, width: '18%', marginLeft: '40%' }}
              loading={false}
              loadingPosition="start"
              startIcon={<GridSaveAltIcon />}
              variant="outlined"
              type="submit"
            >
              POST
            </LoadingButton>
          </form>
        </Box>
      </Modal>
    </Box>
  );
}
