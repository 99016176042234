import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link as RouteLink, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import * as LoginService from '../../services/Auth';
import { APP_AUTH, APP_REGEX, NOTIFICATION_VARIANTS } from '../../constants/AppConstant';
import { enqueueSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { BackdropComponent } from '../shared-components/backdrop/Backdrop';
import { incremented } from '../../store';
import { configureStore } from '@reduxjs/toolkit';
import { counterSlice } from '../../store';
import { Paper } from '@mui/material';
import { ILogo } from '../../styles/components/ILogo';
const store = configureStore({
  reducer: counterSlice.reducer,
});

export default function Login(): React.ReactElement {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['auth_token']);
  const validationSchema = yup.object({
    email: yup.string().required('Please enter email'),
    password: yup
      .string()
      .required('Please enter password')
      .matches(APP_REGEX.PASSWORD_VALIDATOR_REGEX, 'Minimum 8 characters required'),
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      email: '',
      password: '',
      rememberme: false,
    },
    onSubmit: async () => {
      store.dispatch(incremented());
      const body = {
        userName: formik.values.email,
        password: formik.values.password,
      };
      setOpen(true);
      try {
        const { code, token }: any = await LoginService.Login(body);
        if (code === 200 && token !== undefined) {
          setCookie(`auth_token`, token, { path: '/' });
          enqueueSnackbar('Login Successful', {
            autoHideDuration: 3000,
            variant: NOTIFICATION_VARIANTS.SUCCESS,
          });
          setOpen(false);
          navigate('/select');
        } else {
          enqueueSnackbar('Error while login', {
            autoHideDuration: 3000,
            variant: NOTIFICATION_VARIANTS.ERROR,
          });

          setOpen(false);
        }
      } catch (err) {
        enqueueSnackbar('Error while login', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.ERROR,
        });
        setOpen(false);
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <BackdropComponent visible={open} />
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar> */}
        
        <Grid sx={{ paddingTop: 2 }}>
          <ILogo src="/assets/Single_mainlogo_tm.png" />
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 1 }}>
            <TextField
              required
              fullWidth
              id="email"
              autoFocus
              name="email"
              margin="normal"
              autoComplete="email"
              label="Email Address"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="rememberme"
                  name="rememberme"
                  color="primary"
                  value={formik.values.rememberme}
                  onChange={formik.handleChange}
                />
              }
              label="Remember me"
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link variant="body2">
                  <RouteLink to="/auth/password-reset">Forgot password?</RouteLink>
                </Link>
              </Grid>
              <Grid item>
                <Link variant="body2">
                  <RouteLink to="/auth/signup">{"Don't have an account? Sign Up"}</RouteLink>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </Container>
  );
}
