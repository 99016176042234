import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  Button,
  Container,
  Grid,
} from '@mui/material';
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createdUser } from '../../../services/Auth';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../constants/AppConstant';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { CREATED_SUCCESS } from '../../../translations/properties_dev';
export default function AddNewUser(props: any) {
  const [open, setOpen] = React.useState(false);

  const onAddUserSubmit = async () => {
    setOpen(true);
    console.log(`Rest API Call Save Release`);
    try {
      const result = await createdUser(formik.values);
      console.log(`Result ${result}`);
      if (result.data) {
        enqueueSnackbar(CREATED_SUCCESS, {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        });
        setOpen(false);
      } else {
        enqueueSnackbar('Error in creating release', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.WARN,
        });
        setOpen(false);
      }
    } catch (err) {
      console.log(err);
      setOpen(false);
    }
  };

  const validationSchema = yup.object({});

  const formik = useFormik({
    validationSchema,
    initialValues: {
      firstName: '',
      lastName: '',
      userName:'',
      email: '',
      password: '',
      addressLine_1: '',
      addressLine_2: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      status: '',
    },
    onSubmit: onAddUserSubmit,
  });
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <MainContentHeader label="Create New User" />

        <Grid item xs={8} md={8} lg={8}>
          {/* 
                List of attributes
                1. Release Topic name
                2. Description
                3. Planned Release date
                4. Priority (high,medium,low)
                5. Reference ()
                6. Release Demo (demo1)
                7. Source (person / document)
                8. Topic Owners (person-1,person-2,person-3)
              */}
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="firstName"
                  name="firstName"
                  margin="normal"
                  label="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  name="lastName"
                  margin="normal"
                  label="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid> <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="userName"
                  name="userName"
                  margin="normal"
                  label="User Name"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  error={formik.touched.userName && Boolean(formik.errors.userName)}
                  helperText={formik.touched.userName && formik.errors.userName}
                />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  name="email"
                  margin="normal"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="password"
                  name="password"
                  margin="normal"
                  label="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="addressLine_1"
                  name="addressLine_1"
                  margin="normal"
                  label="Address Line 1"
                  value={formik.values.addressLine_1}
                  onChange={formik.handleChange}
                  error={formik.touched.addressLine_1 && Boolean(formik.errors.addressLine_1)}
                  helperText={formik.touched.addressLine_1 && formik.errors.addressLine_1}
                />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="addressLine_2"
                  name="addressLine_2"
                  margin="normal"
                  label="Address Line 2"
                  value={formik.values.addressLine_2}
                  onChange={formik.handleChange}
                  error={formik.touched.addressLine_2 && Boolean(formik.errors.addressLine_2)}
                  helperText={formik.touched.addressLine_2 && formik.errors.addressLine_2}
                />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="city"
                  name="city"
                  margin="normal"
                  label="City"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="state"
                  name="state"
                  margin="normal"
                  label="State"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="country"
                  name="country"
                  margin="normal"
                  label="Country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={formik.touched.country && Boolean(formik.errors.country)}
                  helperText={formik.touched.country && formik.errors.country}
                />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <TextField
                  required
                  fullWidth
                  id="zipCode"
                  name="zipCode"
                  margin="normal"
                  label="Zip Code"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                  helperText={formik.touched.zipCode && formik.errors.zipCode}
                />
              </Grid>

              <br />
              <Grid container xs={12} sx={{ marginLeft: 6, minWidth: '100%', textAlign: 'center' }}>
                <Grid item>
                  <center>
                    <Button sx={{ marginX: 2 }} color="primary" variant="contained" type="submit">
                      Create
                    </Button>

                    <Button sx={{ marginX: 2 }} color="inherit" variant="contained" type="reset">
                      Clear
                    </Button>
                  </center>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}
