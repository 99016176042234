import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups3Rounded';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { SideBarIconColor, SideBarStyle } from '../styles/components/Layout';
import PersonIcon from '@mui/icons-material/Person';
import { AppBar, Button, ListItemButton, ListSubheader, Paper, Toolbar, Typography } from '@mui/material';
import { ILogo } from '../styles/components/ILogo';
import ProjectSelector from '../components/shared-components/ProjectSelector/ProjectSelector';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PasswordIcon from '@mui/icons-material/Password';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import {
  ACCOUNT,
  DASHBOARD,
  METRICS_LIST,
  MILE_STONES_LIST,
  MY_ACTIONS,
  parseURI,
  PRODUCTS_HOME,
  PROFILE,
  PROJECTS,
  RELEASE_TOPICS_LIST,
  ROADMAP_HOME,
  USER_GROUPS,
  USERS,
  VALIDATION_PLANS_HOME,
} from '../utils/parser/URIFormatter';
import { useCookies } from 'react-cookie';

import { configureStore } from '@reduxjs/toolkit';
import { counterSlice } from '../store';
import { APP_NAME } from '../constants/AppConstant';
const store = configureStore({
  reducer: counterSlice.reducer,
});
const drawerWidth = 300;

export default function StickLayout(props: any): React.ReactElement {
  const { projectId = localStorage.getItem('projectId') || '66791cc1cbb87b72c4fc7986' } = useParams();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies(['auth_token']);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  store.subscribe(() => console.log(store.getState()));
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };
  const Logout = () => {
    let date = new Date();
    date.setDate(1);
    date.setMonth(1);
    date.setFullYear(1960);
    removeCookie('auth_token', { path: '/', expires: date });
    console.log('Logout');
    navigate('/');
  };

  const tenantArea = () => {
    const list: any[] = [
      {
        title: 'Dashboard',
        link: parseURI(DASHBOARD, projectId),
        icon: <DashboardIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Road Map',
        link: parseURI(ROADMAP_HOME, projectId),
        icon: <MapOutlinedIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Milestones',
        link: parseURI(MILE_STONES_LIST, projectId),
        icon: <FlagOutlinedIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Release Topics',
        link: parseURI(RELEASE_TOPICS_LIST, projectId),
        icon: <AccountTreeIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Validation Plans',
        link: parseURI(VALIDATION_PLANS_HOME, projectId),
        icon: <SpeedOutlinedIcon sx={SideBarIconColor} />,
      },
      {
        title: 'KPI / Metrics',
        link: parseURI(METRICS_LIST, projectId),
        icon: <InsightsOutlinedIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Product Teams',
        link: parseURI(PRODUCTS_HOME, projectId),
        icon: <GroupWorkOutlinedIcon sx={SideBarIconColor} />,
      },
      {
        title: 'User Groups',
        link: parseURI(USER_GROUPS, projectId),
        icon: <GroupsIcon sx={SideBarIconColor} />,
      },
      {
        title: 'My Actions',
        link: parseURI(MY_ACTIONS, projectId),
        icon: <PendingActionsIcon sx={SideBarIconColor} />,
      },
    ];
    return (
      <>
        {list.map((item) => (
          <ListItemButton component={Link} to={item.link}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText sx={SideBarIconColor} primary={item.title} />
          </ListItemButton>
        ))}
      </>
    );
  };

  const globalSettings = () => {
    const list: any[] = [
      {
        title: 'Profile',
        link: parseURI(PROFILE, projectId),
        icon: <PersonIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Account',
        link: parseURI(ACCOUNT, projectId),
        icon: <AccountBalanceIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Users',
        link: parseURI(USERS, projectId),
        icon: <PeopleAltIcon sx={SideBarIconColor} />,
      },
      {
        title: 'Change Password',
        link: parseURI(MY_ACTIONS, projectId),
        icon: <PasswordIcon sx={SideBarIconColor} />,
      },
    ];
    return (
      <>
        {list.map((item) => (
          <ListItemButton component={Link} to={item.link} id={item.title}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText sx={SideBarStyle} primary={item.title} />
          </ListItemButton>
        ))}
      </>
    );
  };
  const drawer = (
    <div>
      {/* <Toolbar /> */}
      <Paper sx={{ paddingTop: 2 }}>
        <ILogo src="/assets/Single_mainlogo_tm.png" />
      </Paper>
      <Divider />
      <ProjectSelector />
      <Divider />
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Cock-Pit
          </ListSubheader>
        }
      >
        {/* <Typography sx={SideBarHeadingStyle}>Cock-Pit</Typography> */}

        <ListItemButton component={Link} to={parseURI(PROJECTS, projectId)}>
          <ListItemIcon>
            <FolderCopyOutlinedIcon sx={SideBarIconColor} />
          </ListItemIcon>
          <ListItemText sx={SideBarIconColor} primary="Projects" />
        </ListItemButton>
        <Divider />
      </List>

      <List
        sx={{ bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader-tenant-area">
            Tenant Area
          </ListSubheader>
        }
      >
        {tenantArea()}
        <Divider />
      </List>

      <List
        sx={{ bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader-tenant-area">
            Global Setting
          </ListSubheader>
        }
      >
        {globalSettings()}
      </List>
      <Divider />
      <List component="nav" sx={{ bgcolor: 'background.paper' }}>
        <ListItem>
          <Button variant="outlined" sx={{ width: '100%' }} onClick={Logout}>
            <LogoutIcon sx={SideBarIconColor} /> Logout
          </Button>
        </ListItem>
      </List>
    </div>
  );
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            {APP_NAME}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>{drawer}</Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </Box>
  );
}
