import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  Button,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../constants/AppConstant';
import dayjs from 'dayjs';
import React from 'react';
import { AccountInfo } from '../../interface/Account';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getAccountInfo, getUserAndAccountInfo } from '../../services/Auth';
import { CONTAINER_SIZE } from '../../styles/components/SizeConstants';
import { BackdropComponent } from '../shared-components/backdrop/Backdrop';
import MainContentHeader from '../shared-components/main-content/MainContentHeader';

export default function Account() {
  const [open, setOpen] = React.useState(false);
  const [account, setAccount] = React.useState<AccountInfo>();
  const { productId = '', releaseId = '' } = useParams();
  const [loader, setLoader] = React.useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<AccountInfo>();
  const onSubmit: SubmitHandler<AccountInfo> = (data) => console.log(data);
  const navigate = useNavigate();
  const loadUserInfo = async () => {
    console.log('onReleaseFormSubmit Started');
    try {
      setOpen(true);
      // Create new release
      const result = await getUserAndAccountInfo();
      setAccount(result.data.account);
      reset(result.data.account);
      setOpen(false);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error occured while creating a release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.Error,
      });
      setOpen(false);
    }
  };

  useEffect(() => {
    loadUserInfo();
  }, []);

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={2}>
        <MainContentHeader label="Account Info" />
        <Grid item xs={8} md={8} lg={8}>
          {/* 
              List of attributes
              1. Release Topic name
              2. Description
              3. Planned Release date
              4. Priority (high,medium,low)
              5. Reference ()
              6. Release Demo (demo1)
              7. Source (person / document)
              8. Topic Owners (person-1,person-2,person-3)
            */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ mt: 1, padding: 4, width: '100%' }}>
              <Grid item xs={12} sx={{}}>
                <span className="">Company Name</span>
                <TextField required fullWidth id="companyName" margin="normal" {...register('companyName')} />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <span className="">Address Line 1</span>
                <TextField required fullWidth id="addressLine_1" margin="normal" {...register('addressLine_1')} />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <span className="">Address Line 2</span>
                <TextField required fullWidth id="addressLine_2" margin="normal" {...register('addressLine_2')} />
              </Grid>
              <Grid item xs={12} sx={{}}>
                <span className="">City</span>
                <TextField required fullWidth id="city" margin="normal" {...register('city')} />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <span className="">State</span>
                <TextField required fullWidth id="state" margin="normal" {...register('state')} />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <span className="">Country</span>
                <TextField required fullWidth id="country" margin="normal" {...register('country')} />
              </Grid>

              <Grid item xs={12} sx={{}}>
                <span className="">ZipCode</span>
                <TextField required fullWidth id="zipCode" margin="normal" {...register('zipCode')} />
              </Grid>
              <br />
              <Grid container xs={12} sx={{ marginLeft: 6, minWidth: '100%', textAlign: 'center' }}>
                <Grid item>
                  <center>
                    <Button sx={{ marginX: 2 }} color="primary" variant="contained" type="submit">
                      SAVE
                    </Button>
                  </center>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}
