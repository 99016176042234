import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import { Link as RouteLink, useNavigate } from 'react-router-dom';
import { ILogo } from '../../styles/components/ILogo';

import * as LoginService from '../../services/Auth';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../constants/AppConstant';
export default function PasswordReset() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const validationSchema = yup.object({
    email: yup.string().required('Please enter email'),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      email: '',
      username: '',
    },
    onSubmit: async () => {
      console.log("Form Submitted")
      const body = {
        email: formik.values.email,
        username: formik.values.username,
      };
      setOpen(true);
      try {
        const { code, message }: any = await LoginService.forgotPassword(body);
        if (code === 200 && message !== undefined) {
          enqueueSnackbar(message, {
            autoHideDuration: 3000,
            variant: NOTIFICATION_VARIANTS.SUCCESS,
          });
          setOpen(false);
        } else {
          enqueueSnackbar('Error while login', {
            autoHideDuration: 3000,
            variant: NOTIFICATION_VARIANTS.ERROR,
          });

          setOpen(false);
        }
      } catch (err) {
        enqueueSnackbar('Error while login', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_VARIANTS.ERROR,
        });
        setOpen(false);
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid sx={{ paddingTop: 2 }}>
          <ILogo src="/assets/Single_mainlogo_tm.png" />
        </Grid>

        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              autoFocus
            />
            <br />
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Get Password Reset Link
            </Button>
            <Grid container>
              <Grid item xs>
                <Link variant="body2">
                  <RouteLink to="/auth/login">Go Back</RouteLink>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </Container>
  );
}
