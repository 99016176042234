import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  List,
  Paper,
  Snackbar,
  Typography,
} from '@mui/material';

import { useParams } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import CloseIcon from '@mui/icons-material/Close';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import { getReleaseActions } from '../../../services/Auth';
import { ActionsListInterface } from '../../../utils/interface/ActionsList';
import { EmptyResultPaper } from '../../shared-components/table/TableComponent';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';

export function ListActions(props = {}): React.ReactElement {
  const [open, setOpen] = React.useState(false);

  const { projectId = '' } = useParams();
  const [releasesNotifications, setReleasesNotifications] = useState<ActionsListInterface>();

  const getReleaseActionsAPI = async (pageNumber: number = 1) => {
    const result = await getReleaseActions(projectId, pageNumber);
    setReleasesNotifications(result.data);
  };

  useEffect(() => {
    getReleaseActionsAPI();
  }, []);

  const handleClick = () => {
    setOpen(false);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <CssBaseline />
      <Grid container spacing={2}>
        <MainContentHeader label="My Actions" />

        <Grid item xs={8} md={8} lg={8}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {releasesNotifications && releasesNotifications.result ? (
              releasesNotifications.result.map((row: any, index: number) => (
                <Card variant="outlined" sx={{ marginBottom: 1, padding: 3, color: '#000000', backgroundColor: '#fff' }}>
                  <Grid container>
                    <Grid sx={{width:'90%'}}>
                      <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                      {row?.message}
                      </Typography>
                      <Typography variant="h5" component="div">
                        {row?.message}
                      </Typography>
                    </Grid>
                    <Grid sx={{width:'5%'}}>
                      <Button
                        size="small"
                        variant="outlined"
                        href={`/${projectId}/products/${row?.productId}/release/${row?.releaseId}/vote/${row?._id}`}
                        sx={{marginRight:1,height:'100%',width:'100%'}}
                      >
                        <EastIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              ))
            ) : (
              <EmptyResultPaper />
            )}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
}
