import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Pagination,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { BackdropComponent } from '../../shared-components/backdrop/Backdrop';
import { CONTAINER_SIZE } from '../../../styles/components/SizeConstants';
import MainContentHeader from '../../shared-components/main-content/MainContentHeader';
import { TableContentLebel, TableHeaderLabel } from '../../../styles/fonts/FontStyle';
import { CREATE_PROJECTS,  DASHBOARD, parseURI } from '../../../utils/parser/URIFormatter';
import { PageCount } from '../../../utils/lib/PaginationUtil';
import { EmptyResult, extractResult, isResultNotEmpty, TableComponentBody, TableComponentHeader } from '../../shared-components/table/TableComponent';
import { enqueueSnackbar } from 'notistack';
import { NOTIFICATION_VARIANTS } from '../../../constants/AppConstant';
import { getProjects } from '../../../services/Projects';
import { ProjectsList } from '../../../utils/interface/Projects';

export function ListProjects(props = {}): React.ReactElement {
  const navigate = useNavigate();
  const { projectId = '' } = useParams();
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [projects, setProjects] = useState<ProjectsList>();

  const releaseTopicsAPI = async (pageNumber: number = 1) => {
    setOpen(true);
    try {
      const releaseTopics: any = await getProjects(pageNumber);
      setProjects(releaseTopics.data);
    } catch (err) {
      enqueueSnackbar('Error in creating release', {
        autoHideDuration: 3000,
        variant: NOTIFICATION_VARIANTS.WARN,
      });
    }

    setOpen(false);
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('Changed Value  ', value);
    setPage(value);
    releaseTopicsAPI(value);
  };
  useEffect(() => {
    releaseTopicsAPI();
  }, []);

  const switchProject = (value:any) => {
    localStorage.removeItem('projectId');
    localStorage.setItem('projectId',value);
    navigate(`/${value}/dashboard`)
  }

  return (
    <Container component="main" maxWidth={CONTAINER_SIZE} sx={{ mt: 4, mb: 4 }}>
      <BackdropComponent visible={open} />
      <Grid container spacing={1}>
        <MainContentHeader
          label="Projects"
          buttonHref={parseURI(CREATE_PROJECTS, projectId)}
          buttonLabel="Create Project"
        />
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableComponentHeader>
                <TableRow>
                  <TableCell sx={TableHeaderLabel}>Topic Name</TableCell>
                  <TableCell sx={TableHeaderLabel}>Actions</TableCell>
                </TableRow>
              </TableComponentHeader>
              <TableComponentBody>
                {isResultNotEmpty(projects) ? (
                  extractResult(projects).map((row: any, index: number) => (
                    <TableRow key={row?._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={TableContentLebel} component="th" scope="row">
                        {row?.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button sx={{ marginRight: 1 }} disabled variant="outlined" onChange={switchProject} >
                          Edit
                        </Button>
                        {/* <Button variant="outlined" onChange={e=>{switchProject(row?._id)}}>Outlined</Button> */}
                        {/* <Button variant="outlined" onChange={switchProject(row?._id)}>Outlined</Button> */}
                        <Button sx={{ marginRight: 1 }} variant="outlined" href={parseURI(DASHBOARD,row?._id)}>
                          Switch
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <EmptyResult/>
                )}
              </TableComponentBody>
            </Table>
          </TableContainer>
          <Pagination
            count={PageCount(projects?.totalCount)}
            sx={{ padding: 2, marginLeft: '30%' }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
