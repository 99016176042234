import { PaginationConst } from '../constants/AppConstant';
import { PageItemCount } from '../utils/lib/PaginationUtil';
import HttpClient from './HttpClient';
import { RELEASE_SERVICE_HOST, RELEASES_API_PREFIX } from './RestConstants';





export async function patchProductById(projectId:string,productId: String, releaseObj: Object = {}) {
  const response = await HttpClient.PATCH({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/products/${productId}`,
    data: releaseObj,
  });
  return response;
}


export async function getProducts(projectId:string,pageNumber:number=1) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/products?skip=${PageItemCount(pageNumber)}&size=${PaginationConst.PAGE_SIZE}`
  });
  return response.data;
}

export async function getProductById(projectId:string,productId: String) {
  const response = await HttpClient.GET({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/products/${productId}`
  });
  return response;
}



/**
 * @description Create new release
 * @param {Object} releaseObj
 * @returns {Promise}
 */
export async function createProduct(projectId:string,releaseObj: Object = {}) {
  const response = await HttpClient.POST({
    BASE_URL: RELEASE_SERVICE_HOST,
    END_POINT: `${RELEASES_API_PREFIX}/projects/${projectId}/products`,
    data: releaseObj,
  });

  return response;
}

